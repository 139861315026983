import React, { createContext, useContext, useState } from 'react';

// Create the context
const MMRContext = createContext();

// Provider component
export const MMRProvider = ({ children }) => {
  const [mmr, setMmr] = useState(0);
  const [mmrGain, setMmrGain] = useState(0);

  // Function to determine the background image based on MMR
  const getBackgroundImage = () => {
    if (mmr >= 2200) return 'url(/img/ranks.diamond.png)';
    if (mmr >= 1700) return 'url(/img/ranks.sk-gold.png)';

    return 'url(/img/ranks/sk-silver.png)';
  };

  return (
    <MMRContext.Provider value={{ mmr, setMmr,mmrGain, setMmrGain, getBackgroundImage }}>
      {children}
    </MMRContext.Provider>
  );
};

// Custom hook to use the MMR context
export const useMMR = () => {
  return useContext(MMRContext);
};
