import React, { useEffect, useRef } from 'react';
import { Engine, Scene, FreeCamera, Vector3, HemisphericLight, SceneLoader, Color4, Mesh } from '@babylonjs/core';
import '@babylonjs/loaders';
import { useAuth } from '../contexts/AuthContext';

const ShogiPieceSpinner = ({show}) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const engine = new Engine(canvasRef.current, true, { preserveDrawingBuffer: true, stencil: true });
    const scene = new Scene(engine);
    
    // Set the scene background to transparent
    scene.clearColor = new Color4(0, 0, 0, 0);

    // Create a basic camera positioned directly in front of the piece
    const camera = new FreeCamera('camera1', new Vector3(0, 0, -3), scene);
    camera.setTarget(Vector3.Zero());
    camera.attachControl(canvasRef.current, true);

    // Create a basic light
    const light = new HemisphericLight('light1', new Vector3(0, 1, 0), scene);
    light.intensity = 0.9;

    const mb = canvasRef.current.width < 800 ? true:false;
    // Load the Shogi piece model
    SceneLoader.ImportMesh("", "/3d/", "bishop.obj", scene, function (meshes) {

      
      let piece = Mesh.MergeMeshes(meshes, undefined, undefined, undefined, undefined, true);
      piece.scaling = new Vector3(10, 10, 10);
      piece.position = mb ? new Vector3(-0.4, 1, 0):new Vector3(-1, 0, 0);
      // Spin the piece
      piece.rotation.x += 1.65;
      scene.onBeforeRenderObservable.add(() => {
        piece.rotation.y += 0.01;
      });
    });
    SceneLoader.ImportMesh("", "/3d/", "rook.obj", scene, function (meshes) {

      
        let piece = Mesh.MergeMeshes(meshes, undefined, undefined, undefined, undefined, true);
        piece.scaling = new Vector3(10, 10, 10);
        piece.position = mb ? new Vector3(0.4, 1, 0):new Vector3(1, 0, 0);
        // Spin the piece
        piece.rotation.x += 1.65;
        piece.rotation.y += 1.8;
        scene.onBeforeRenderObservable.add(() => {
          piece.rotation.y += 0.01;
        });
      });
    engine.runRenderLoop(() => {
      scene.render();
    });

    // Resize the canvas when the window is resized
    window.addEventListener('resize', () => {
      engine.resize();
    });

    return () => {
      engine.dispose();
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundImage: 'url(/img/grandbg.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        zIndex: 1000,
        display: !show ? 'none':'block'
      }}
    />
  );
};

export default ShogiPieceSpinner;
