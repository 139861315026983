import React, { createContext, useContext, useState } from 'react';

// Create the context
const AvatarContext = createContext();

// Provider component
export const AvatarProvider = ({ children }) => {
  const [avatar, setAvatar] = useState('');

  // Function to determine the background image based on MMR


  return (
    <AvatarContext.Provider value={{ avatar, setAvatar}}>
      {children}
    </AvatarContext.Provider>
  );
};

// Custom hook to use the MMR context
export const useAvatar = () => {
  return useContext(AvatarContext);
};
