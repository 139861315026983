import { liftCheck, moveAnim, stopLift } from "../3d/animations";
import possibleMoves from "./possibleMoves";
import * as BABYLON from '@babylonjs/core';
import socket from "../../socket";
import { movePiece, stopMovingPiece } from "./movement";
export const selectPiece = (piece, index, client) => {  //Selecting captured pieces
  if (!client.myTurn) return;
  client.moveTiles.tiles.forEach((t) => {
    if (t != client.activeTile) t.setEnabled(false);
  });
  liftCheck(client);
  client.pieceSelected = piece[0];
  console.log(piece, index);

  console.log(client.capPieces[0], client.capPieces[0]);

  let pawn = false;
  let flagged = []; //Flags the X axis that contains pawns
  if (piece[0] == 1) pawn = true;
  if (pawn) {
    for (let i = 0; i < 9; i++) {  //THSI WONT LAST LONG, ENSURE MESH NAMES(owner) ARE CHANGED WHEN CAPTURED
      for (let v = 0; v < 9; v++) {
        if (client.board[i][v][0] === 1 && client.board[i][v][1].name === client.userNumber) { flagged.push(v); continue };
      }
    }
  }
  if (index != -1) {
    for (let y = 0; y < 9; y++) {
      for (let x = 0; x < 9; x++) {
        if (flagged.indexOf(x) !== -1) continue;
        if (client.board[y][x][0] === 0) {
          let id = client.board[y][x][0];
          let relativeY = client.userNumber === 1 ? y : 8 - y;  // Y value reversed if user is #2

          if (id === 1 || id === 5) {  //Pawns and Lances cannot be dropped on the last row
            if (relativeY === 8) continue;
          }
          else if (id === 4) {  //Knights cannot be dropped in the 2nd last or last row
            if (relativeY >= 7) continue;
          }
          else {  //No pieces can be dropped without 
            if (possibleMoves(client.userNumber, client.board, x, y, id).length === 0) continue;
          }
          let plane = client.moveTiles.tiles.find(t => t.boardx === x && t.boardy === y);  //Find tile in same location as legal move

          plane.material = client.greenMat;  //All tiles are reused
          plane.setEnabled(true);
        }
      }
    }
  }
}

export const dropPiece = (client, data) => {
  client.moveTiles.tiles.forEach((t) => {
    if (t != client.activeTile) t.setEnabled(false);
  });
  liftCheck(client);
  console.log(client.capPieces)
  let capIndex = client.myTurn ? 0 : 1;
  console.log('dropping');
  console.log(data);
  let x = data.x;
  let y = data.y;
  let id = data.id;

  let pieceArray;
  for (let i = 0; i < client.capPieces[capIndex].length; i++) {
    if (client.capPieces[capIndex][i][0] === id) pieceArray = client.capPieces[capIndex][i];
  }
  let mesh = pieceArray[1];
  mesh.name = client.myTurn ? client.userNumber === 1 ? '1' : '2' : client.userNumber == 1 ? '2' : '1';
  mesh.position.x = 0;
  mesh.position.z -= capIndex === 1 ? 0.055 : 0.01;

  mesh.rotation.y += Math.PI;
  mesh.position.z = client.userNumber === 2 ? 0.01 : -0.055;
  mesh.position.x -= 0.165;

  mesh.position.x += -0.33 * x;
  mesh.position.z -= 0.365 * y;
  mesh.position.y = 0;
  mesh.rotation.z = 0;
  mesh.isPickable = capIndex == 0;
  client.board[y][x] = pieceArray;
  client.capPieces[capIndex].splice(client.capPieces[capIndex].indexOf(pieceArray), 1);
  setTimeout(() => {
    if (client.myTurn) {
      client.myTurn = false;
    }
    else client.myTurn = true;
  }, 1000)
  client.setCapturedPiecesValue(client.capPieces);
  console.log(mesh.position)
}

export const checkmate = (client) => {
  client.setCheckValue(c => !c);
}

export function resetBoard(client) {
  client.setShowPromoteUiValue(false);
  liftCheck(client);
  let newBoard = client.board;
  client.setGameRunningValue(false);
  client.turn = 1;
  let anim = BABYLON.Animation.CreateAndStartAnimation("light", client.light0, "intensity", 10, 5, 1, 0, undefined, undefined, undefined, client.scene);
  anim.loopAnimation = false;
  client.myTurn = false;
  client.setDcPause(false);

    client.moveTiles.tiles.forEach((t) => {
      t.setEnabled(false);
    });

    for(let y = 0; y < 9; y++) {
      for(let x = 0; x < 9; x++) {
        if(client.board[y][x][0] == 0) continue;

        let tx = parseInt(client.board[y][x][1].uid.charAt(1));
        let ty = parseInt(client.board[y][x][1].uid.charAt(0));
        let mesh = client.board[y][x][1];
        client.scene.stopAnimation(mesh);
        mesh.position = mesh.basePos;
        mesh.rotation = mesh.baseRot;
        newBoard[ty][tx] = client.board[y][x];
        mesh.x = tx;
        mesh.y = ty;
        mesh.boardx = tx;
        mesh.boardy = ty;
        mesh.isPickable = true;
      }
    }
    client.board = newBoard;
    let meshes = [];
    for(let i = 0; i < Math.max(client.capturedPieces[0].length, client.capturedPieces[1].length); i++) {
      if(client.capturedPieces[0][i] != undefined) meshes.push(client.capturedPieces[0][i][1]);
      if(client.capturedPieces[1][i] != undefined) meshes.push(client.capturedPieces[1][i][1]);
    }

    meshes.forEach((m) => {
      m.position = m.basePos;
      m.rotation = m.baseRot;
    });

    let anim2 = BABYLON.Animation.CreateAndStartAnimation("light2", client.light0, "intensity", 10, 10, 0, 1, undefined, undefined, undefined, client.scene);
    anim2.loopAnimation = false;


    client.cameraOne.alpha = Math.PI * 2;
    client.cameraOne.beta = Math.PI / 2.5;

}

export const userPromoteInput = (client) => {
  return new Promise((resolve) => {
    let count = 0;
    client.setShowPromoteUiValue(true);
    let pmintv = setInterval(function() {
      console.log(client.showPromoteUi);
      count++;
      if(count > 300) {
        clearInterval(pmintv);
        resolve(null)
      }
      if(client.userInputtedPromote()) {
        client.setShowPromoteUiValue(false);
        client.setPromote(false);
        console.log("resolved");
        clearInterval(pmintv);
        resolve(client.promotePiece === 1 ? '+':undefined);
      }
    }, 100)
})
}

export const handleClick = async(e, client) => {
  if(!client.myTurn) return;
  if(client.dcPause) return;
  switch (e.type) {
    case BABYLON.PointerEventTypes.POINTERDOWN:                     //This is somewhat broken

      if(e.pickInfo.pickedMesh == undefined) {
        client.setShowPromoteUiValue(false);
        liftCheck(client);
        client.moveTiles.tiles.forEach((t) => {
          if(t != client.activeTile) t.setEnabled(false);
        });
        client.pieceSelected = false;
          return;
      };

      if(client.liftedMesh && e.pickInfo.pickedMesh.uid === client.liftedMesh.uid);  
      

      if(client.moveTiles.tiles.indexOf(e.pickInfo.pickedMesh) != -1 || client.moveTiles.tiles.filter((m) => m.boardx == e.pickInfo.pickedMesh.boardx && m.boardy == e.pickInfo.pickedMesh.boardy).length > 0) {
        
        let data = e.pickInfo.pickedMesh.moveData;
        if(data != undefined) {

          liftCheck(client);
          client.moveTiles.tiles.forEach((t) => {
            if(t != client.activeTile) t.setEnabled(false);
          });
          client.setPromoteUiPosValue([e.event.clientX, e.event.clientY]);
          if(!client.pieceSelected && client.board[data.y][data.x][0] / 10 < 1 && '134567'.includes(toString(client.board[data.y][data.x][0])) &&
          (data.ty <= 2 && client.userNumber == 2 ) || (data.ty >= 6 && client.userNumber == 1)) {
            data.p = await userPromoteInput(client);  //Wait for user to decide whether or not to promote piece
          }
          if(!client.pieceSelected) {
            socket.emit('movePiece', data);
            if(client.board[data.ty][data.tx][0] !== 0) {
              let capturex = client.board[data.ty][data.tx];
              let captureMeshx = capturex[1];
              let captureMeshPosx = captureMeshx.position.clone();
              client.undoCapture = captureMeshx;
              client.undoCapturePos = captureMeshPosx;
            }
            moveAnim(client, data, () => stopMovingPiece(client));
            console.log(client.capPieces)
            client.interpolated = true;
            client.interpolatedMove = data;
          }
          else {
            socket.emit('dropPiece', {x: data.tx, y: data.ty, id: client.pieceSelected});
            //dropPiece({dropPiece: [data.tx, data.ty]}, true);
          }
          stopLift(client);
          client.moveTiles.tiles.forEach((t) => {
            if(t != client.activeTile) t.setEnabled(false);
          });
          client.pieceSelected = false;
        return;
        }
      }
      client.setShowPromoteUiValue(false);
      client.pieceSelected = false;
        let modelMeshes = client.scene.meshes.filter((m) => m.position.x == e.pickInfo.pickedMesh.position.x && m.position.z == e.pickInfo.pickedMesh.position.z);
        if(modelMeshes.length == 0) return;
        if(modelMeshes[0].name != client.userNumber) return;
        client.moveTiles.tiles.forEach((t) => {
          if(t != client.activeTile) t.setEnabled(false);
        });
        let pieces = ['pawn', 'gold', 'silver', 'knight', 'lance', 'rook', 'bishop', 'king1'];
        for (let y = 0; y < 9; y++) {
          for (let x = 0; x < 9; x++) {
              if (client.board[y][x][0] == 0) continue;
              if (client.board[y][x][1].position.x == e.pickInfo.pickedMesh.position.x && client.board[y][x][1].position.z == e.pickInfo.pickedMesh.position.z) {
                  console.log(e.pickInfo.pickedMesh.position);
                  let id = client.board[y][x][0];
                  client.setPickedValue(pieces[id - 1]);
                  liftCheck(client);
  
                  if (!e.pickInfo.pickedMesh.lifted) {
                    client.liftedMesh = e.pickInfo.pickedMesh;
                    client.liftedMesh.lifted = true;
  
                      let liftAnim = BABYLON.Animation.CreateAndStartAnimation("lift", e.pickInfo.pickedMesh, 'position.y', 15, 5, e.pickInfo.pickedMesh.position.y, e.pickInfo.pickedMesh.position.y + 0.09);
                      liftAnim.loopAnimation = false;
                  }
                  
                  let moves = possibleMoves(client.userNumber, client.board, x, y, id);
                  console.log(client.moveTiles)
                  for (let i = 0; i < moves.length; i++) {
                      let tileY = moves[i][1];
                      let tileX = moves[i][0];
                      if (tileX < 0 || tileX > 8 || tileY < 0 || tileY > 8) continue;
                      if (client.board[tileY][tileX][1] && client.board[tileY][tileX][1].name == client.userNumber) continue;
  
                      let plane;
                      console.log(tileX, tileY);
                      try {
                          plane = client.moveTiles.tiles.find(t => t.boardx == tileX && t.boardy == tileY);
                          plane.moveData.x = x;
                          plane.moveData.y = y;
                          plane.material = client.greenMat;
                          if (client.board[tileY][tileX][0] != 0) plane.material = client.plainRedMat;
                          else plane.material = client.greenMat;
                          plane.setEnabled(true);
                      } catch (e) {
                          console.log(e);
                      }
                  }
                  break;
              }
          }
      }
      break;
    case BABYLON.PointerEventTypes.POINTERUP:
      break;
    default:
      break;
  }

}