import * as BABYLON from '@babylonjs/core';
import { moveAnim, promoteAnim } from '../3d/animations';

export const stopMovingPiece = (client) => {
    client.movingPiece = false;
  }
  const notAnimating = (client) => {
    return new Promise((resolve) => {
        const check = () => {
            if (!client.movingPiece) {
                resolve(true);
            } else {
                // If still animating, check again after a short delay
                requestAnimationFrame(check);
            }
        };
        check(); // Start the checking loop
    });
};

  const animating = (client) => {
    return !client.animating;
  }
export async function movePiece(client, data, interpolatedMove) {  //data.[username, x, y, tx, ty]
    if(client.animating) {
        await notAnimating(client);
    }
    client.animating = true;
    if(!client.interpolated){
        client.movingPiece = true;
        moveAnim(client, data, () => stopMovingPiece(client))
    }

    if(client.board[data.ty][data.tx][0] != 0) { //Piece exists in target location
        const cindex = data.username == client.username ? 0:1;
        const capture = client.board[data.ty][data.tx];
        const captureMesh = capture[1];
        
        console.log(client.myTurn);
        if(client.myTurn){
          client.capPieces[0].push([capture[0], captureMesh])
          client.setCapturedPieces(client.capPieces);
        }
        else {
          client.capPieces[1].push([capture[0], captureMesh])
          client.setCapturedPieces(client.capPieces);
        }
    
        
        console.log(client.capPieces)
        //capturedPieces.splice(capturedPieceIndex, 1);
      }  

    client.board[data.ty][data.tx] = client.board[data.y][data.x];
    client.board[data.y][data.x] = [0];
    client.board[data.ty][data.tx][1].boardx = data.tx;
    client.board[data.ty][data.tx][1].boardy = data.ty;
  
    if(client.board[data.ty][data.tx][0] == 8) client.kingPos = [data.tx, data.ty]
  
  

    if(data.p === '+') {
        client.board[data.ty][data.tx][0] = parseInt(client.board[data.ty][data.tx][0] + '' + client.board[data.ty][data.tx][0]) //Promoted pieces have doubled IDs:  Pawn(1), Promoted Pawn(11) 
        setTimeout(() => {
            promoteAnim(client, data);
        }, 500)
    }


    if(client.activeTile){ client.activeTile.setEnabled(false);  client.activeTile.material = client.greenMat;client.scene.stopAnimation(client.activeTile)} //Reset active tile
    client.activeTile = client.moveTiles.tiles.find(t => t.boardx == data.tx && t.boardy == data.ty);
    client.activeTile.material = client.redMat;
    client.scene.beginAnimation(client.activeTile, 0, 60, true);
    client.activeTile.setEnabled(true);
  setTimeout(() => {
    if(client.myTurn) {
        client.myTurn = false;
    }
    else client.myTurn = true;
  }, 1000);
  if(!interpolatedMove){ client.interpolated = false;}
  client.animating = false;
  }