import React, { createContext, useState, useContext } from 'react';

// Create a context for the game state
const GameContext = createContext();

// Provider component
export const GameProvider = ({ children }) => {

  const [loaded, setLoaded] = useState(false);
  const [turn, setTurn] = useState(1);  // Turn of current game 
  const [gameMode, setGameMode] = useState('');  // GAME MODE: Ranked/Casual/Tourn
  const [opp, setOpp] = useState(['', 0]);
  const [capturedPieces, setCapturedPieces] = useState([[], []]);
  const [picked, setPicked] = useState('');

  return (
    <GameContext.Provider value={{ 
      loaded, setLoaded, 
      turn, setTurn, 
      gameMode, setGameMode, 
      opp, setOpp, 
      capturedPieces, setCapturedPieces, 
      picked, setPicked 
    }}>
      {children}
    </GameContext.Provider>
  );
};

// Custom hook to use the Game context
export const useGame = () => {
  return useContext(GameContext);
};
