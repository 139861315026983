import * as BABYLON from '@babylonjs/core';


export const glowAnim = new BABYLON.Animation("glow", "material.emissiveColor", 30, BABYLON.Animation.ANIMATIONTYPE_COLOR3, BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE);
//Here we have chosen a loop mode, but you can change to :
//  Use previous values and increment it (BABYLON.Animation.ANIMATIONLOOPMODE_RELATIVE)
//  Restart from initial value (BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE)
//  Keep the final value (BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT)
let activeRed = new BABYLON.Color3(0.55, 0, 0);
// Animation keys
var keys = [];
//At the animation key 0, the value of scaling is "1"
keys.push({
  frame: 0,
  value: activeRed
});

//At the animation key 100, the value of scaling is "1"
keys.push({
  frame: 25,
  value: BABYLON.Color3.Black()
});
keys.push({
  frame: 45,
  value: BABYLON.Color3.Black()
});
keys.push({
  frame: 60,
  value: activeRed
});
glowAnim.setKeys(keys);


export const promoteAnim = (client, data) => {
  setTimeout(() => {
    let modelMeshes = client.board[data.ty][data.tx][1];

    client.board[data.ty][data.tx][0] = parseInt(client.board[data.ty][data.tx][0] + '' + client.board[data.ty][data.tx][0]) //Promoted pieces have doubled IDs:  Pawn(1), Promoted Pawn(11) 
    let anim222 = BABYLON.Animation.CreateAndStartAnimation('promote', modelMeshes, 'position.y', 10, 5, modelMeshes.position.y, modelMeshes.getBoundingInfo().boundingBox.maximum.y * 7.25);
    console.log(modelMeshes.getBoundingInfo().boundingBox.minimum);
    anim222.loopAnimation = false;
    let animm = BABYLON.Animation.CreateAndStartAnimation('promote', modelMeshes, 'rotation.z', 10, 5, 0, Math.PI);
    animm.loopAnimation = false;

    let animmm = BABYLON.Animation.CreateAndStartAnimation('promote', modelMeshes, 'rotation.x', 10, 5, 0, -0.16772614);
    animmm.loopAnimation = false;
    //setTimeout(promoteEndAnim(modelMeshes[0]), 500);

  }, 1000);
}

const movePieceEase = new BABYLON.BezierCurveEase(0.1, 0.2, 0.4, 1.5);
export const moveAnim = (client, data, cb) => {

  let tempx = Math.floor(8 * (1.0 - (data.x / 8)));
  let temptx = Math.floor(8 * (1.0 - (data.tx / 8)));
  let dy, dx;
  dx = (temptx - tempx) * -0.33;
  dy = (data.ty - data.y) * 0.365;
  //if(movingPiece) await notMovingPiece();
  //movingPiece = true;
  console.log(data);


  //Move the piece
  let modelMeshes = client.board[data.y][data.x][1];

  let vary = client.board[data.y][data.x][0] < 9 ? 0 : client.board[data.y][data.x][1].getBoundingInfo().boundingBox.maximum.y * 7.25;
  let targetPos = new BABYLON.Vector3(modelMeshes.position.x - dx, vary, modelMeshes.position.z - dy);
  client.lastAnimPosition = modelMeshes.position.clone();
  client.lastAnimMesh = modelMeshes;
  client.movingPiece = true;
  const anim = BABYLON.Animation.CreateAndStartAnimation("movePiece", modelMeshes, "position", 10, 10, modelMeshes.position, targetPos, undefined, movePieceEase, cb);
  anim.loopAnimation = false;
  client.lastAnim = anim;


  if (client.board[data.ty][data.tx][0] != 0) { //Piece exists in target location

    let capture = client.board[data.ty][data.tx];
    let captureMesh = capture[1];
    const captureMeshPos = captureMesh.position.clone();
    if (capture[0] > 8) capture[0] = Math.floor(capture[0] / 10);
    let zpos = data.username == client.username ? client.zpositions[0] : client.zpositions[1];
    let cindex;
    client.capturePlane.position.x = captureMesh.position.x;
    client.capturePlane.position.z = captureMesh.position.z;

    client.capturePlane.position.y = 0.1;
    client.capturePlane.playAnimation(1, 36, false, 30, () => { client.capturePlane.stopAnimation(); client.capturePlane.position.y = -5 });

    captureMesh.position.y = -1;
    captureMesh.position.z = -1.5;

    captureMesh.x = 10;
    captureMesh.y = 10;
    //capturedPieces.splice(capturedPieceIndex, 1);
  }

  if (modelMeshes == client.liftedMesh) {
    client.liftedMesh.lifted = false
  };

  return anim;
}
export const stopLift = (client) => {
  try {
    client.liftedMesh.lifted = false;
    client.liftedMesh = false;
  } catch (e) {

  }

}
export const liftCheck = (client) => {
  if (client.liftedMesh && client.liftedMesh.lifted === true) {

    if (client.liftedMesh.id < 9) {
      let dropAnim = BABYLON.Animation.CreateAndStartAnimation("drop", client.liftedMesh, 'position.y', 15, 3, client.liftedMesh.position.y, 0);
      dropAnim.loopAnimation = false;
      try {
        stopLift(client);
      } catch (e) { };
    } else {
      let dropAnim = BABYLON.Animation.CreateAndStartAnimation("drop", client.liftedMesh, 'position.y', 15, 3, client.liftedMesh.position.y, client.liftedMesh.getBoundingInfo().boundingBox.maximum.y * 7.25);
      dropAnim.loopAnimation = false;
      try {
        stopLift(client);
      } catch (e) { };
    }
  }
}

