
import './App.css';

import {useCookies} from 'react-cookie';
import LoginModal from './components/LoginModal.js';
import React, { useState, useEffect, useRef, createContext, useContext, useCallback, useMemo } from 'react';
import {login, register} from './game-client/network/auth.js';
import GameEndModal from './components/GameEndModal';

import SidePanel from './components/SidePanel/SidePanel';

import Tooltip from 'react-bootstrap/Tooltip';

import CapturedPiecesUI from './components/CapturedPiecesUI';
import PromoteUi from './components/PromoteUi';
import Checkmate from './components/Checkmate';
import GameStart from './components/ingame/GameStart';
import {loadImgs} from './img/images';
import FontFaceObserver from 'fontfaceobserver';
import Resign from './components/ingame/Resign';
import GameClient from './game-client/client.js';
import { loadSockets } from './game-client/network/sockets.js';

import ProfileFrameWrapper from './components/ProfileFrame/ProfileFrameWrapper.js';
import socket from './socket.js';
import { MMRProvider, useMMR } from './contexts/MMRContext.js';
import { useAuth } from './contexts/AuthContext.js';
import { useGame } from './contexts/GameContext.js';
import Versus from './components/Versus.js';
import { ImageContext } from './contexts/ImageContext.js';
import { useAvatar } from './contexts/AvatarContext.js';
import { useNavigate } from 'react-router-dom';
import BetaSignupModal from './components/BetaSignupModal';

//import data from './img/data';



  //Opponent disconnected 
  const DcAlert = (props) => {
    return(props.dcPause ?
    <div className="alert alert-secondary" role="alert">
      Waiting for opponent to reconnect...
      <div className="spinner-grow text-primary" role="status">
      </div>
    </div>:null)
  }

  //Game menu UI
  const GameUI = (props) => {
    return(props.gameRunning ? 
      <Resign v={props}></Resign>:null
    );
  }


  //Current Turn 
  const TurnUi = (props) => {
    return props.gameRunning ? <p style={{position: "absolute", bottom: "0.7rem", left: "7.5rem", fontSize: "0.7rem"}}>Turn: <br/> <span style={{fontSize: "2.5rem", lineHeight: "1.4rem"}}>{props.turn}</span></p>:null;
  }

  const SettingsUI = (props) => {
    return (props.showSettings ? 
      <div id="settings-container">
        <h2>Settings</h2>

      </div>
      :null)
  }

  const SurrenderIcon = (props) => {
    return props.gameRunning ? <svg id="surrender" xmlns="https://www.w3.org/2000/svg" width="32" height="32" fill="red" className="bi bi-flag-fill" viewBox="0 0 16 16">
    <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001"/>
  </svg>:null
  }


  const IgProfileFrame = (props) => {
    return(props.gameRunning ?
      <div className="profileFrame profileContainer">
        
        <h3>{props.mmr}</h3>
      </div>:null
    )
  }

  const PieceNameOverlay = (props) => {
    return(
      props.showPieceOverlay && !props.showPromoteUi ? 
          <Tooltip id="pieceOverlay" style={{top: props.promoteUiPos[1], left: props.promoteUiPos[0]}}>
            {props.showPieceOverlay}
          </Tooltip>
    :null
    )
  }
  // <div id="sidePanel">
  // <div className="glow-on-hover" onClick={props.navClick}>
  //   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
  //     <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
  //   </svg>
  // </div>




export function App() {
  
  const [cookies, setCookie, removeCookie] = useCookies(['uuid']);
  
  const { loggedIn, setLoggedIn, username, setUsername } = useAuth();

  const {mmr, setMmr, mmrGain, setMmrGain, getBackgroundImage} = useMMR();;  //Current players elo rating & change
  const { 
    loaded, setLoaded, 
    turn, setTurn, 
    gameMode, setGameMode, 
    opp, setOpp, 
    capturedPieces, setCapturedPieces, 
    picked, setPicked 
   } = useGame();

  const [gameRunning, setGameRunning] = useState(false);

  const [dcPause, setDcPause] = useState(false);  //Opponent DC?
  const [userFontSize, setUserFontSize] = useState('2.5rem'); //Username font size for scaling longer usernames down
  const [showSettings, setShowSettings] = useState(false);  //Settigns UI show state

  const [searching, setSearching] = useState(false);  //User searching for a game?
   const {avatar, setAvatar} = useAvatar();

  const [showGameEnd, setShowGameEnd] = useState(false);  //User searching for a game?
  const [gameResult, setGameResult] = useState(0);  //User searching for a game?


  const [showPromoteUi, setShowPromoteUi] = useState(false);
  const [promoteUiPos, setPromoteUiPos] = useState([0, 0]);

  const [showSidePanel, setShowSidePanel] = useState(true);
   const navigate = useNavigate();
  const [socketid, setSocketid] = useState('');
  const [loadedFonts, setLoadedFonts] = useState(false);
   const {imagesLoaded} = useContext(ImageContext);
   const [skGold, setSkGold] = useState(false);

  const fonts = useCallback(() => {
    var font = new FontFaceObserver('Oleo Script');
      
      font.load().then(() => {
        console.log('loaded');
        setLoadedFonts(true);
      }, function () {
        setLoadedFonts(true);
      });
  }, [])
  const [check, setCheck] = useState(false);
  const [playStart, setPlayStart] = useState(false);
  let canvas = useRef(false);

  
  const [settings, setSettings] = useState({
    lang: "eng",
    engine: "3D"
  })



  const navClick = useCallback((ingame) => {
    canvas.current = canvas.current ? canvas.current:document.getElementById('gameCanvas');
    if(canvas.current) canvas.current.classList.toggle('blur');
    setShowSidePanel(!showSidePanel);
    const panel = document.getElementById('panelCont');
    panel.classList.toggle('fadeOut');
    if(!ingame) {
      const slider = document.getElementsByClassName('slider')[0];
      slider.classList.toggle('active')
    }
    document.getElementById('sidePanel').classList.toggle('slidePanel');

  }, [])

  const [client, setClient] = useState(new GameClient(gameRunning, setGameRunning, setLoaded, dcPause, setDcPause, setTurn, setSearching, gameMode, setOpp, setGameResult, setShowGameEnd, setMmr, mmr, setMmrGain, showPromoteUi, setShowPromoteUi, setPromoteUiPos, capturedPieces, setCapturedPieces, navClick, setPicked, setCheck, setPlayStart, setSkGold));




  //One time run at start
  useEffect(() => {   
    loadSockets(setAvatar, setCookie, removeCookie, setLoggedIn, setUsername, setMmr, navClick, setSocketid, cookies.uuid, client);
    client.initClient();
    fonts();
    setTimeout(() => {
      canvas.current = document.getElementById('gameCanvas');
      let inputs = document.getElementsByClassName("registerInputs");
      for(let i = 0; i < inputs.length; i++) {
        inputs[i].removeAttribute('readonly');
      }
    }, 750);
    return () => {
      socket.close();
    }
  }, []);

  useEffect(() => { //Hides the side panel when game is initiated TO DO: ANIMATE CAPTURED PIECES UI
    if(gameRunning){
        navClick();
    }
      
  }, [gameRunning])


  //On logged in
  useEffect(() => {
    if(loggedIn){
      navigate('/')
      if(username.length > 9) setUserFontSize('1.8rem');
      else if(username.length > 13) setUserFontSize('1.5rem');
      
      document.getElementById('sidePanel').style.display = 'flex';
    }

  }, [loggedIn]);
  
  //On game client loaded


  const handleLogin = useCallback(function() {
    let loginInputs = document.getElementsByClassName('loginInput');
    for(let i =0; i < loginInputs.length; i++) {
      if(loginInputs[i].value == '') {
        return;
      }
    }
    login();
  }, [])
  const handleRegister = useCallback(function() {
    let registerInputs = document.getElementsByClassName('registerInputs');
    for(let i =0; i < registerInputs.length; i++) {
      if(registerInputs[i].value == '') {
        return document.getElementById('errorP').innerHTML = '&#x2022; All fields are required';
      }
    }
    if(registerInputs[2].value != registerInputs[3].value) {
      return document.getElementById('errorP').innerHTML = '&#x2022; Passwords do not match';
    }
    register();
  }, [])


  return (
    <div className="App">
{/*  
      <SidePanel skGold={skGold} waitLoaded={client.waitLoaded} loadedFonts={loadedFonts} cookie={cookies.uuid} show={showSidePanel} navClick={navClick} loggedIn={loggedIn} setSearching={setSearching} setGameMode={setGameMode} gameMode={gameMode} searching={searching} username={username} mmr={mmr} userFontSize={userFontSize} loaded={loaded} gameRunning={gameRunning}/>
      <div className="glow-on-hover"onClick={navClick}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
        <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
      </svg></div>

      <DcAlert dcPause={dcPause}/>

      <TurnUi gameRunning={gameRunning} turn={turn}/>
      <SurrenderIcon/> */}
  <BetaSignupModal />
      {/* <PieceNameOverlay showPieceOverlay={showPieceOverlay} showPromoteUi={showPromoteUi}promoteUiPos={promoteUiPos}/>
       */}
      {/* <GameUI setShowSidePanel={setShowSidePanel} setGameRunning={setGameRunning} gameRunning={gameRunning} loaded={loaded} username={username} mmr={mmr} userFontSize={userFontSize} searching={searching} setSearching={setSearching} setGameMode={setGameMode}/>
      
      <PromoteUi showPromoteUi={showPromoteUi} setShow={setShowPromoteUi} promoteUiPos={promoteUiPos} picked={picked} setPromote={client.setPromote ? client.setPromote:null}/>
      <GameStart playStart={playStart}/>
      <Checkmate playCheck={check} gameRunning={gameRunning}/>

      <ProfileFrameWrapper showSidePanel={showSidePanel} gameRunning={gameRunning} mainProfile ={{
                avatarUrl:avatar,
                name:username,
                eloRating:mmr,
                country:"UK"
            }}
            secondaryProfile ={{
              avatarUrl: opp[0] === 'AI' ? "ai-robot-avatar.png":"anime-avatar (1).png",
              name: opp[0],
              eloRating: opp[1],
              country:"UK",
              secondary:true
          }}
            />
      <Versus opp = {opp} mainProfile ={{
                avatarUrl:avatar,
                name:username,
                eloRating:mmr,
                country:"UK"
            }}
            secondaryProfile ={{
              avatarUrl: opp[0] === 'AI' ? "ai-robot-avatar.png":"anime-avatar (1).png",
              name: opp[0],
              eloRating: opp[1],
              country:"UK",
              secondary: true }}/>
      <GameEndModal show={showGameEnd} setShow={setShowGameEnd} mmr={mmr} gameResult={gameResult} opp={opp} mmrGain={mmrGain} navClick={navClick}/>
              
      <LoginModal loggedIn={loggedIn} cookies={cookies} handleLogin={handleLogin} handleRegister={handleRegister} show={!loaded || !imagesLoaded || !loggedIn}  loaded={loaded && imagesLoaded}/>
      <CapturedPiecesUI capturedPieces={capturedPieces} gameRunning={gameRunning} selectPiece={client.selectPiece}></CapturedPiecesUI>
       */}
    </div>
  );
}


//3D STUFF:
//CAM 1:
//Y: -0.033 m
//Z: 0.178 m
//ROT: 10
//SCALE 0.07


//light : 0.607245 m;


// DONE:   KING2, PAWN2, LANCE2