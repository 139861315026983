// context/ImageContext.js
import React, { createContext, useEffect, useState } from 'react';
import { loadImages, loadAvatars } from '../utils/loadImages';


export const ImageContext = createContext(null);

export const ImageProvider = ({ children }) => {
    const [images, setImages] = useState({});
    const [imagesLoaded, setLoaded] = useState(false);

    useEffect(() => {
        const start = Date.now();
        const staticImagePaths = [
            '/img/ranks/sk-diamond.png',
            '/img/ranks/sk-gold.png',
            '/img/ranks/sk-silver.png',
            // Add any other specific image paths here
        ];
        const load = async () => {

                const loadedAvatars = await loadAvatars(); // Load avatars
                loadImages(staticImagePaths)
                    .then((loadedImages) => {
                        setImages({
                            ...loadedImages,
                            //...loadedAvatars, // Combine static images with avatars
                        });
                        console.log('laoded all')
                        setLoaded(true);
                        console.log(Date.now() - start)
                    })
                    .catch((error) => {
                        console.error('Error loading images:', error);
                    });
        }
        load();
        
    }, []);

    return (
        <ImageContext.Provider value={{ images, imagesLoaded }}>
            {children}
        </ImageContext.Provider>
    );
};
