import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { CookiesProvider } from "react-cookie";
import { MMRProvider } from './contexts/MMRContext';
import { AuthProvider } from './contexts/AuthContext';
import { GameProvider } from './contexts/GameContext';
import { ImageProvider } from './contexts/ImageContext';
import { AvatarProvider } from './contexts/AvatarContext';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <CookiesProvider>
    <AuthProvider>
      <MMRProvider>
        <GameProvider>
          <ImageProvider>
            <AvatarProvider>
            <App />

            </AvatarProvider>
          </ImageProvider>
        </GameProvider>
      </MMRProvider>
    </AuthProvider>
  </CookiesProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
