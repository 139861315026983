import { useCallback, useContext, useState } from "react";
import { Button, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import NewBotGame from "./NewBotGame";
import socket from "../../../../../socket";
import MyTable from "./Table";
import { ImageContext } from "../../../../../contexts/ImageContext";
import { useNavigate } from "react-router-dom";
export const RenderHome = (props) => {
    const [showNewBotGame, setShowNewBotGame] = useState(false);
    const {images} = useContext(ImageContext);
    const navigate = useNavigate();
    const hideNewBotGame = useCallback(() => {
        setShowNewBotGame(false);
    }, [])
    return (
        <Container className="activeUi uiPanel" id="panelHome" style={{overflow: 'hidden'}}>
            <div style={{height: 'calc(100% - 7.75rem)', marginTop: "1.75rem"}}>
                <h2 style={{marginBottom: "0px"}}>Play</h2>
                <div id="ui">
            
                <Searching searching={props.searching} setSearching={props.setSearching} gameMode={props.gameMode}/>
                <MenuUI loaded={props.waitLoaded} hideNewBotGame={hideNewBotGame} gameRunning={props.gameRunning} setSearching={props.setSearching} setGameMode={props.setGameMode} searching={props.searching} showNewBotGame={showNewBotGame} setShowNewBotGame={setShowNewBotGame}/>
                <div className="uidiv profileContainer" style={{display: 'flex', flexDirection:'column', alignItems: 'center'}}>
                <div id="profile-frame" className="wreathbg">
            
                <OverlayTrigger
                    delay={{ hide: 450, show: 200 }}
                    overlay={(props) => (
                <Tooltip {...props}>
                    Elo rating
                </Tooltip>
                )}
                placement="left">
                    <span id="info-ico">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"  fill="white" className="bi bi-info-circle-fill" viewBox="0 0 16 16" style={{pointerEvents: "none"}}>
                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                        </svg>
                    </span>
                    </OverlayTrigger>
                    
                    </div>
                    <p id="mmr">{props.mmr}</p>
                    <Button size="sm" style={{display:'block'}} onClick={() => {document.getElementsByClassName('nav-item')[1].click();
                    }}>View your Profile</Button>
                    </div>
                </div>
            </div>
            <h2>Spectate</h2>
            {props.loggedIn ? <MyTable/>:null}
        </Container>
    )
}


const Searching = (props) => {
    return props.searching ?
        <div id="searching"><p>Finding a {props.gameMode} Match.</p>
            <div className="spinner-grow text-primary" role="status"></div>
            <Button size="sm" id="cancel-search" onClick={() => {socket.emit("cancelQueue");props.setSearching(false);}}>Cancel</Button>
        </div>
        :null
}
const MenuUI = (props) => {
    return (
        !props.searching ? <><div className="uidiv" id="menu-div">
         <Button 
                className="menuButton" 
                onClick={async () => { 
                    await props.loaded; 
                    socket.emit('queue', { mode: "0" }); 
                    props.setSearching(true); 
                    props.setGameMode('Ranked'); 
                }} 
                disabled={props.gameRunning}
            >
                RATED
            </Button>
            <Button 
                className="menuButton" 
                onClick={async () => { 
                    await props.loaded; 
                    socket.emit('queue', { mode: "1" }); 
                    props.setSearching(true); 
                    props.setGameMode('Casual'); 
                }} 
                disabled={props.gameRunning}
            >
                CASUAL
            </Button>
            <Button 
                className="menuButton" 
                onClick={async () => { 
                    await props.loaded; 
                    props.setShowNewBotGame(true); 
                    props.setGameMode('AI'); 
                }} 
                disabled={props.gameRunning}
            >
                VS AI
            </Button>
            <Button
                className="menuButton disabled"
                disabled
            >
                TOURNAMENTS
            </Button>
        </div><NewBotGame show={props.showNewBotGame && !props.gameRunning} hideNewBotGame={props.hideNewBotGame} setShow={props.setShowNewBotGame} /></>:null)
}
