import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { loadStripe } from "@stripe/stripe-js";
import { useCookies } from "react-cookie";

// Load your Stripe public key
const stripePromise = loadStripe("pk_test_51Pr0p9ChwfN4jiOMtcvb80qMSP8hHhRRBl0CVIZ1xOqP7aD7pkwLdjhC5J5EPjps7jUtWrptKxn9VMIiq0EAWpUm00HNqYTXBL");

export const SKGold = (props) => {
    const [loading, setLoading] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['uuid']);
  
    const handlePayment = async () => {
        setLoading(true);
    
        try {
            const response = await fetch('/create-checkout-session', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    priceId: 'price_1PrMQeChwfN4jiOMwSDekhtf', // Replace with your product price ID
                    userId: cookies.uuid, // Pass the user ID or other identifier
                }),
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok.');
            }
    
            const data = await response.json();
            if (data.url) {
                window.location.href = data.url; // Redirect to the Stripe Checkout page
            } else {
                console.error('No URL returned from server.');
            }
        } catch (error) {
            console.error('Error redirecting to checkout:', error);
            setLoading(false);
        }
    };
    
    return (
        <Modal
            show={props.show}
        
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >   <div style={{display: 'flex',flexDirection: 'row', paddingBottom: '2rem', marginBottom: '2rem'}}>
            <div style={{width: '2rem', paddingRight: '1rem', paddingTop: '1rem'}}></div>
            <Modal.Body style={{ maxHeight: '20rem', overflowY: 'visible' }}>
            <div id="crown" style={{ width: '100%', height: '5rem', marginBottom: '1rem', backgroundSize: '5rem' }}></div>

            <h2 style={{width: 'max-content',margin: 'auto', marginBottom: '1rem'}}>Purchase SK <span style={{color: 'gold'}}>Gold</span> Subscription</h2>
                <ul style={{listStyleType: 'none', display: 'flex', alignContent: 'center', justifyContent: 'center', flexDirection: 'column', width: 'max-content', margin:'auto', marginBottom: '1rem' }}>
                    <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    &nbsp;&nbsp;Get access to exclusive features with SK Gold. In-game hints, avatars and more.
                    </li><br/>
                    <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    &nbsp;&nbsp;Support the development and continuity of Shogi Kings.
                    </li><br/>
                    <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                    &nbsp;&nbsp;Get early access to new features before they are released.
                    </li>
                    
                </ul>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '1rem' }}>
                    <Button
                        className=""
                        onClick={handlePayment}
                        disabled={loading}

                    >
                        {loading ? 'Processing...' : 'Purchase SK Gold'}
                    </Button>
                </div>
            </Modal.Body>

            <div style={{width: '2rem', paddingRight: '1rem', paddingTop: '1rem'}}>
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16" style={{cursor: 'pointer'}} onClick={() => {props.setShow(false)}}>
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
</svg></div>
            </div>
        </Modal>
    );
};
