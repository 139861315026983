export default function possibleMoves(user, board, x, y, id) {
    console.log(x, y);
    const pMoves = [];
    const trans = (n) => (user === 1 ? n : -n);

    const isValidPosition = (newX, newY) => newX >= 0 && newX <= 8 && newY >= 0 && newY <= 8;

    const isOccupied = (x, y) => board[y][x][0] !== 0;

    const checkExistingPiece = (dx, dy) => {
        const newX = x + dx;
        const newY = y + dy;
        return isValidPosition(newX, newY) && board[newY][newX][0] !== 0;
    };

    const addMove = (dx, dy) => {
        const newX = x + dx;
        const newY = y + dy;
        if (isValidPosition(newX, newY) && (!checkExistingPiece(dx, dy) || board[newY][newX][1] !== user)) {
            pMoves.push([newX, newY]);
        }
    };

    const addLinearMoves = (directions) => {
        directions.forEach(([dx, dy]) => {
            let i = 1;
            while (true) {
                const newX = x + (i * dx);
                const newY = y + (trans(i * dy));
    
                if (!isValidPosition(newX, newY)) break; // Stop if out of bounds
                
                const piece = board[newY][newX];
                if (piece[0] !== 0) { // If there's a piece at the new position
                    if (piece[1].owner !== user) { // Check if the piece is from the opposite user
                        pMoves.push([newX, newY]); // Add capture move
                    }
                    break; // Stop if a piece blocks the way
                }
                
                pMoves.push([newX, newY]); // Add the move
                i++;
            }
        });
    };
    

    const addLanceMoves = (directions) => {
        directions.forEach(([dx, dy]) => {
            let i = 1;
            while (true) {
                const newX = x + (i * dx);
                const newY = y + (i * dy);
                
                if (!isValidPosition(newX, newY)) break; // Stop if out of bounds
                
                if (isOccupied(newX, newY)) {
                    if (board[newY][newX][1] !== user) {
                        pMoves.push([newX, newY]); // Add capture move
                    }
                    break; // Stop if a piece blocks the way
                }
                
                pMoves.push([newX, newY]); // Add the move
                i++;
            }
        });
    };
    function addGoldMoves(x, y) {
        const pMoves = [];
    
        // Move one square forward
        addMove(0, trans(1)); // Move forward
        addMove(0, -trans(1)); // Move backward
        
        // Move diagonally forward
        addMove(1, trans(1)); // Move diagonally right forward
        addMove(-1, trans(1)); // Move diagonally left forward
        
        // Move one square horizontally
        addMove(1, 0); // Move right
        addMove(-1, 0); // Move left
        
        return pMoves;
    }
    
    const addDiagonalMoves = (includeOrthogonal = false) => {
        const directions = [
            [1, 1], [-1, -1], [-1, 1], [1, -1],
            ...(includeOrthogonal ? [[1, 0], [-1, 0], [0, 1], [0, -1]] : [])
        ];
        addLinearMoves(directions);
    };

    const pieces = {
        pawn: () => addMove(0, trans(1)),
        gold: () => {
            addGoldMoves(x, y)
        },
        silver: () => {
            addMove(0, trans(1));   // Move top forward
            addMove(1, trans(1));   // Move top-right
            addMove(1, -trans(1));  // Move bottom-right
            addMove(-1, trans(1));  // Move top-left
            addMove(-1, -trans(1)); // Move bottom-left
            
        },
        knight: () => {
            [[1, 2], [-1, -2]].forEach(([dx, dy]) => addMove(dx, dy));
        },
        lance: () => addLanceMoves([[0, 1]]),
        rook: () => addLinearMoves([[1, 0], [0, 1], [-1, 0], [0, -1]]),
        promotedRook: () => {
            addLinearMoves([[1, 0], [0, 1], [-1, 0], [0, -1]]);
            addDiagonalMoves();
        },
        bishop: () => addDiagonalMoves(),
        promotedBishop: () => addDiagonalMoves(true),
        king: () => {
            [[1, 0], [-1, 0], [1, 1], [1, -1], [0, 1], [0, -1], [-1, 1], [-1, -1]]
                .forEach(([dx, dy]) => addMove(trans(dx), trans(dy)));
        },
        default: () => pieces.gold() // Default to gold's moves
    };

    const pieceIdToFunc = {
        1: 'pawn',
        2: 'gold',
        3: 'silver',
        4: 'knight',
        5: 'lance',
        6: 'rook',
        66: 'promotedRook',
        7: 'bishop',
        77: 'promotedBishop',
        8: 'king',
    };

    const pieceFunc = pieceIdToFunc[id] || 'default';
    pieces[pieceFunc]();

    return pMoves;
}
