// utils/loadImages.js
export const loadImages = (imagePaths) => {
    return new Promise((resolve, reject) => {
        const images = {};
        let loadedCount = 0;

        imagePaths.forEach((path) => {
            try {
                const img = new Image();
                img.src = path;

                img.onload = () => {
                    images[path] = img;
                    console.log(path)
                    loadedCount++;
                    if (loadedCount === imagePaths.length) {
                        resolve(images);
                    }
                };

                img.onerror = () => {
                    reject(new Error(`Failed to load image: ${path}`));
                };
            } catch (error) {
                reject(error);
            }
        });
    });
};
export const loadAvatars = async () => {
    const images = {};
    const context = require.context('../../public/img/avatars', false, /\.(png|jpe?g|svg)$/);

    const loadPromises = context.keys().map((key) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = context(key);

            img.onload = () => {
                images[key.replace('./', '')] = img; // Store the Image object itself
                resolve();
            };

            img.onerror = (error) => {
                reject(new Error(`Failed to load image: ${key}`));
            };
        });
    });

    // Wait for all images to be loaded
    await Promise.all(loadPromises);

    return images;
};
