import io from 'socket.io-client';
import * as BABYLON from '@babylonjs/core';
import '@babylonjs/loaders/OBJ';
import '@babylonjs/inspector';
import possibleMoves from './logic/possibleMoves';
import { AlphaState, SpriteManager } from '@babylonjs/core';
import { login, register } from './network/auth';
import { selectPiece, dropPiece, checkmate, resetBoard, handleClick } from './logic/game-logic';
import { importAll } from './3d/import';
import { reconnect, sockets } from './network/sockets';
import { glowAnim } from './3d/animations';
import socket from '../socket';

export default class GameClient {
    constructor(
        gameRunning, setGameRunning, setLoaded, dcPause, setDcPause, setTurn, setSearching, gameMode,
        setOpp, setGameResult, setShowGameEnd, setMmr, mmr, setMmrGain, showPromoteUi, setShowPromoteUi,
        setPromoteUiPos, capturedPieces, setCapturedPieces, navClick, setPicked, setCheck, setPlayStart,
        setSkGold
    ) {
        this.socket = socket;
        this.username = null;
        this.myTurn = false;
        this.userNumber = 2;
        this.zpositions = [0.6, -3.58];
        this.loaded = false;
        this.rcBoard = false;
        this.kingPos = [4, 0];
        this.board = [
            [['lance'], ['knight'], ['silver'], ['gold'], ['king1'], ['gold'], ['silver'], ['knight'], ['lance']],
            [[0], ['rook'], [0], [0], [0], [0], [0], ['bishop'], [0]],
            [['pawn'], ['pawn'], ['pawn'], ['pawn'], ['pawn'], ['pawn'], ['pawn'], ['pawn'], ['pawn']],
            [[0], [0], [0], [0], [0], [0], [0], [0], [0]],
            [[0], [0], [0], [0], [0], [0], [0], [0], [0]],
            [[0], [0], [0], [0], [0], [0], [0], [0], [0]],
            [['pawn'], ['pawn'], ['pawn'], ['pawn'], ['pawn'], ['pawn'], ['pawn'], ['pawn'], ['pawn']],
            [[0], ['bishop'], [0], [0], [0], [0], [0], ['rook'], [0]],
            [['lance'], ['knight'], ['silver'], ['gold'], ['king1'], ['gold'], ['silver'], ['knight'], ['lance']]
        ];
        this.moveTiles = { tiles: [], data: [] };
        this.greenMat = null;
        this.redMat = null;
        this.plainRedMat = null;
        this.commands = {}; // Execute functions from server side
        this.capPieces = [[], []]; // Used to update CapturedPiecesUi
        this.dropPiece = null;
        this.checkmate = null;
        this.selectPiece = selectPiece;
        this.pieceSelected = false;
        this.liftedMesh = null;
        this.capturePlane = null;
        this.promotePiece = false;
        this.commands = {};
        this.setPromote = false;
        this.moveTiles = { tiles: [], data: [] };
        this.setSkGold = setSkGold;
        this.turn = 1;
        this.interpolated = false;
        // Constructor assignments
        this.gameRunning = gameRunning;
        this.setGameRunning = setGameRunning;
        this.dcPause = dcPause;
        this.searching = false;
        this.setLoaded = setLoaded;
        this.setDcPause = setDcPause;
        this.setTurn = setTurn;
        this.setSearching = setSearching;
        this.setOpp = setOpp;
        this.setGameResult = setGameResult;
        this.setShowGameEnd = setShowGameEnd;
        this.setMmr = setMmr;
        this.setMmrGain = setMmrGain;
        this.setShowPromoteUi = setShowPromoteUi;
        this.setPromoteUiPos = setPromoteUiPos;
        this.showPromoteUi = showPromoteUi;
        this.promoteUiPos = null;
        this.capturedPieces = capturedPieces;
        this.setCapturedPieces = setCapturedPieces;
        this.setPicked = setPicked;
        this.setCheck = setCheck;
        this.setPlayStart = setPlayStart;


        this.undoCapure = false;
    }

    // Setters
    setGameRunningValue(value) {
        this.gameRunning = value;
        if (this.setGameRunning) this.setGameRunning(value);
    }

    setDcPauseValue(value) {
        this.dcPause = value;
        if (this.setDcPause) this.setDcPause(value);
    }

    setTurnValue(value) {
        this.turn = value;
        if (this.setTurn) this.setTurn(value);
    }
    setSkGoldValue(value) {
                this.skGold = value;
        if (this.setSkGold) this.setSkGold(value);
    }
    setSearchingValue(value) {
        this.searching = value;
        if (this.setSearching) this.setSearching(value);
    }

    setOppValue(value) {
        this.opp = value;
        if (this.setOpp) this.setOpp(value);
    }

    setGameResultValue(value) {
        this.gameResult = value;
        if (this.setGameResult) this.setGameResult(value);
    }

    setShowGameEndValue(value) {
        this.showGameEnd = value;
        if (this.setShowGameEnd) this.setShowGameEnd(value);
    }

    setMmrValue(value) {
        this.mmr = value;
        if (this.setMmr) this.setMmr(value);
    }

    setMmrGainValue(value) {
        this.mmrGain = value;
        if (this.setMmrGain) this.setMmrGain(value);
    }

    setShowPromoteUiValue(value) {
        this.showPromoteUi = value;
        if (this.setShowPromoteUi) this.setShowPromoteUi(value);
    }

    setPromoteUiPosValue(value) {
        this.promoteUiPos = value;
        if (this.setPromoteUiPos) this.setPromoteUiPos(value);
    }

    setCapturedPiecesValue(value) {
        this.capturedPieces = value;
        if (this.setCapturedPieces) this.setCapturedPieces(value);
    }

    setPickedValue(value) {
        this.picked = value;
        if (this.setPicked) this.setPicked(value);
    }

    setCheckValue(value) {
        this.check = value;
        if (this.setCheck) this.setCheck(value);
    }

    setPlayStartValue(value) {
        this.playStart = value;
        if (this.setPlayStart) this.setPlayStart(value);
    }

    setSocket(socket) {
        this.socket = socket;
    }
    setLoadedValue(loaded) {
        this.loaded = loaded;
        if (this.setLoaded) this.setLoaded(loaded);
    }
    initEventListeners() {
        this.socket.on('cmd', this.handleCommand.bind(this));
        this.socket.on('dc', this.handleDisconnect.bind(this));
        // Add other event listeners
    }

    setPromote(p) {
        this.promotePiece = p;
    }

    handleCommand(data) {
        this.socket.on("cmd", (data) => {  //Socket Controller
            this.commands[Object.keys(data)[0]](data);
        });
    }

    async initClient() {
        // Initial setup
        var canvas = document.getElementById("gameCanvas");
        this.engine = new BABYLON.Engine(canvas, true);
        this.scene = new BABYLON.Scene(this.engine);
        let width = canvas.width;
        this.cameraOne = new BABYLON.ArcRotateCamera("CameraOne", Math.PI * 2, Math.PI / 2.5, 5, this.scene.getMeshById('board'), this.scene);

        // Rest of your setup
        this.scene.useRightHandedSystem = true;
        this.scene.clearColor = new BABYLON.Color3(0.05, 0.05, 0.05);

        // Add a light source
        this.light0 = new BABYLON.HemisphericLight("Omni", new BABYLON.Vector3(0, 1, 0), this.scene);
        this.light0.intensity = 0;

        // Set up glow layer
        var gl = new BABYLON.GlowLayer("glow", this.scene);
        gl.intensity = 1;

        // Animation and camera setup
        let anim = BABYLON.Animation.CreateAndStartAnimation("light", this.light0, "intensity", 5, 10, 0.85, 1);
        anim.loopAnimation = false;


        // Continue setting materials and other assets
        this.greenMat = new BABYLON.StandardMaterial("green", this.scene);
        this.greenMat.emissiveColor = new BABYLON.Color3(0, 1, 0);
        this.greenMat.diffuseColor = new BABYLON.Color3(0, 1, 0);

        this.plainRedMat = new BABYLON.StandardMaterial("red", this.scene);
        this.plainRedMat.emissiveColor = new BABYLON.Color3(1, 0, 0);
        this.plainRedMat.diffuseColor = new BABYLON.Color3(1, 0, 0);

        this.redMat = new BABYLON.StandardMaterial("red", this.scene);
        this.redMat.emissiveColor = new BABYLON.Color3(0.325, 0.12, 0.04);
        this.redMat.diffuseColor = new BABYLON.Color3(0.325, 0.12, 0.04);

        let poofManager = new SpriteManager("poof", './img/poof.png', 36, 300, this.scene);
        this.capturePlane = new BABYLON.Sprite("poof", poofManager);
        this.capturePlane.position.y = -300;
        this.capturePlane.cellIndex = 1;

        await importAll(this);

        let actionManager = new BABYLON.ActionManager(this.scene);
        let hoverColor = new BABYLON.Color3(0.1, 0.1, 0.1);

        for (let y = 0; y < 9; y++) {
            for (let x = 0; x < 9; x++) {
                let plane = BABYLON.MeshBuilder.CreatePlane("MoveTile", { width: 0.305, height: 0.328 }, this.scene);
                plane.boardx = x;
                plane.boardy = y;
                plane.position.x = this.board[0][0][1].position.x - (x * 0.33);
                plane.position.z = -0.04 - (y * 0.3625);
                plane.position.y = 0.01;
                plane.rotate(BABYLON.Axis.X, Math.PI / 2);
                plane.setEnabled(false);
                plane.animations.push(glowAnim);
                var obj = { x: 0, y: 0, tx: x, ty: y };
                plane.moveData = obj;
                this.moveTiles.tiles.push(plane);
            }
        }

        this.scene.disablePhysicsEngine();
        this.cameraOne.useFramingBehavior = true;
        let framingBehavior = this.cameraOne.getBehaviorByName('Framing');
        framingBehavior.zoomOnMesh(this.scene.getMeshById('board'));
        this.cameraOne.lowerRadiusLimit = null;
        framingBehavior.radiusScale = 0.8;
        this.engine.setViewport(new BABYLON.Viewport(0, 0, 800, 600));
        this.cameraOne.beta = 0.3;

        this.engine.runRenderLoop(() => {
            this.scene.render();
        });

        this.socket = sockets(this);
        this.setLoadedValue(true);
        this.loaded = true;
    }
    async waitLoaded() {
        await new Promise((res) => {
            // Function to check the condition
            const checkCondition = () => {

                if (this.loaded) {
                    clearInterval(waitForLoaded);
                    res(); // Resolve the promise when the condition is met
                }
            };

            // Check the condition every 150 milliseconds
            const waitForLoaded = setInterval(checkCondition, 150);
        })
    }
    async initGame(data) {
        const time = Date.now();
                // Set opponent and other initializations
                if (data.user2) {
                    if (data.user1 == this.username) { this.setOppValue([data.user2, data.mmr2]); }
                    else { this.setOppValue([data.user1, data.mmr1]); }
                }
        await this.waitLoaded();

        console.log("init game")
        let framingBehavior = this.cameraOne.getBehaviorByName('Framing');
        this.engine.setViewport(new BABYLON.Viewport(0, 0, 800, 600));

        if (this.rcBoard) reconnect(this);

        framingBehavior.zoomOnMesh(this.scene.getMeshById('board'));
        this.setGameRunning(true);
        this.cameraOne.beta = 0.3;

        // Set up camera position and user-specific logic
        if (data.user1 == this.username) {
            this.cameraOne.alpha = Math.PI / 2;
            this.userNumber = 1;
            this.myTurn = true;
            for (let y = 6; y < 9; y++) {
                for (let x = 0; x < 9; x++) {
                    if (this.board[y][x][0] != 0) {
                        this.board[y][x][1].isPickable = false;
                        this.board[y][x][1].owner = data.user2;
                    }
                }
            }
        } else {
            for (let y = 0; y < 3; y++) {
                for (let x = 0; x < 9; x++) {
                    if (this.board[y][x][0] != 0) {
                        this.board[y][x][1].isPickable = false;
                        this.board[y][x][1].owner = data.user1;
                    }
                }
            }
            this.cameraOne.alpha = -Math.PI / 2;
            this.userNumber = 2;
            this.zpositions.reverse();
        }



        this.scene.onPointerObservable.add(async (e) => {
            // Handle pointer events
            handleClick(e, this);
        });
        this.socket.emit('rdy');
        this.setSearching(false);
        this.setPlayStart(this.userNumber - 1);

    }
    handleDisconnect() {
        // Handle disconnect
    }

    loadBoard() {
        return new Promise((resolve, reject) => {
            BABYLON.SceneLoader.ImportMesh('', './3d/', 'board.obj', this.scene, async (sc) => {
                // Handle board loading
                await importAll(this);
                resolve();
            }, null, (str, exc) => {
                reject(exc);
            });
        });
    }


    resetBoard() {
        resetBoard(this);
    }

    userInputtedPromote() {
        return this.promotePiece;
    }

    // Other methods
}
