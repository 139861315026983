import { io } from "socket.io-client";
import socket from "../../socket";

export function login() {
    let loginInputs = document.getElementsByClassName('loginInput');
    let socketio = socket;
    socketio.emit('login', {username: loginInputs[0].value.toLowerCase(),password: loginInputs[1].value});
  }
  
export function register() {
    let registerInputs = document.getElementsByClassName('registerInputs');
    let socketio = socket;
    socketio.emit('register', {email: registerInputs[0].value.toLowerCase(),
            username: registerInputs[1].value.toLowerCase(),
            password: registerInputs[2].value})
  }