import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ShogiPieceSpinner from './ShogiPieceSpinner';
import SocialMedia from './SocialMedia';

export default function BetaSignupModal(props) {
  const [email, setEmail] = useState('');
  const [showSuccess, setShowSuccess] = useState(false); // State to manage success message visibility
  const [error, setError] = useState(''); // State to manage error message visibility

  const handleSubmit = (event) => {
    event.preventDefault();
    // Reset error message
    setError('');

    // Handle the email submission logic here
    fetch('/beta-signup', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: email })
    })
    .then(response => {
      if (response.ok) {
        setShowSuccess(true); // Show success message if response is OK
        setEmail(''); // Clear the email input
      } else {
        // Handle errors if needed
        return response.json().then(json => {
          setError(json.error || 'Failed to submit email'); // Set error message from response
        });
      }
    })
    .catch(error => {
      setError('An error occurred. Please try again.'); // Handle network errors
      console.error('Error:', error);
    });
  };

  return (
    <>
      <div id="all-ranks">
        <img src="/img/ranks/all-ranks.png" alt="Ranks"/>
        <h1 style={{fontSize:'1rem'}}>Climb the ranks and claim your crown!</h1>
      </div>
      <ShogiPieceSpinner show={true}/>
      <Modal
        show={true}
        backdrop="static" // Static backdrop
        keyboard={false} // Prevent closing with keyboard
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="beta-signup"
      >
        <Modal.Header style={{ paddingBottom: '0px'}}>
          <Modal.Title id="contained-modal-title-vcenter" style={{ display: "flex", flexDirection: 'column' }}>
            <img src="/img/crown.png" id="logincrown" alt="Crown"/>
            <img src="/img/text_logo.png" id="loginlogo" alt="Shogi Kings"/>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{alignItems: 'center', display: 'flex', flexDirection: 'column' ,width: '85%', margin: 'auto'}}>
          {showSuccess ? (
            <div style={{ textAlign: 'center' }}>
              <h2>Thank you for signing up!</h2>
              <p style={{fontSize: '0.85rem'}}>You will be notified when Shogi Kings Beta is released.</p>
            </div>
          ) : (
            <>
              <h2>Sign Up for the Beta Release</h2>
              <p>Completely Free</p>
              <Form onSubmit={handleSubmit} style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                <InputGroup className="mb-3" style={{width: '70%'}}>
                  <Form.Control
                    type="email"
                    placeholder="Enter your email"
                    aria-label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </InputGroup>
                <Button className='menubutton' type="submit" variant="primary">
                  Sign Up
                </Button>
                {error && <p style={{ color: 'red', marginTop: '10px' }}>{error}</p>} {/* Error message */}
                <SocialMedia/>
              </Form>
            </>
          )}
        </Modal.Body>
      </Modal>
      <p className="crfooter" style={{display: 'block', textAlign: 'center', width: '75%', left: '50%', transform: 'translateX(-50%)', position: 'absolute', zIndex: 5000, bottom: '1rem', fontSize: '0.5rem'}}>
        © 2024 Shogi Kings. All rights reserved. All content on this website, including text, graphics, logos, images, and software, is the property of Shogi Kings and is protected by international copyright laws. Unauthorized use or reproduction of any content without permission is strictly prohibited.
      </p>
    </>
  );
}
