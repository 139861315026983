import * as BABYLON from '@babylonjs/core';


export const importAll = async(client) => {
    let pieceRotation = 0;
    let pieces = ['pawn', 'gold', 'silver', 'knight', 'lance', 'rook', 'bishop', 'king1'];
    let dropTiles = { tiles: [], data: [] }
    const impMesh = () => {

        return new Promise(async (resolve, reject) => {
            let pceCount = 0;
            let checkCount = () => {
                pceCount++;
                if (pceCount == 40) {
                    return true;
                }
                return false;
            }
            for (let i = 0; i < 9; i++) {
                if (i == 3) {
                    pieceRotation = Math.PI;
                }
                for (let v = 0; v < 9; v++) {
                    if (client.board[i][v] == 0) continue;
                    await importPiece(i, v, client.board);
                    if (checkCount()) resolve();

                }
            }
        })
    }

    function importPiece(i, v) {
        return new Promise((resolve, reject) => {
            BABYLON.SceneLoader.ImportMesh('', '/3d/', client.board[i][v][0] + '.obj', client.scene, (sc) => {
                let mesh = BABYLON.Mesh.MergeMeshes(sc, undefined, undefined, undefined, undefined, true);
                mesh.scaling = new BABYLON.Vector3(10, 10, 10);
                mesh.alphaIndex = 5;
                mesh.addRotation(0, i > 3 ? Math.PI : 0, 0);
                mesh.position.x -= 0.165;
                mesh.position.z -= i < 3 ? 0.055 : 0.01;
                //mesh.showBoundingBox = true;
                mesh.position.x += -0.33 * v;
                mesh.position.z -= 0.365 * i;
                mesh.name = i > 4 ? '2' : '1';
                mesh.id = toString(pieces.indexOf(client.board[i][v][0]) + 1);
                mesh.basePos = mesh.position;
                mesh.baseRot = mesh.rotation;
                mesh.lifted = false;
                mesh.boardx = v;
                mesh.boardy = i;
                //mesh.imposter = new BABYLON.PhysicsImpostor(mesh, BABYLON.PhysicsImpostor.BoxImpostor, {friction: 0,restitution: 0, mass: 0.4, disableBidirectionalTransformation: true }, scene);
                client.board[i][v][1] = mesh;
                client.board[i][v][0] = pieces.indexOf(client.board[i][v][0]) + 1;
                mesh.uid = i + '' + v;
                resolve();
            });
        })
    }
    function impBoard() {
        return new Promise((resolve,reject) => {
          BABYLON.SceneLoader.ImportMesh('','/3d/', 'board.obj', client.scene, (sc) => {
      
            let mesh = BABYLON.Mesh.MergeMeshes(sc, undefined, undefined, undefined, undefined, true);
            mesh.id = "board";
            mesh.scaling = new BABYLON.Vector3(10, 10, 10);
            mesh.position.x += 0.33 * -4.5;
            mesh.position.z -= ( 0.365 * 4.5) - 0.15;
            
            mesh.isPickable = false;
            mesh.checkCollisions = false;
          
            resolve();
          }, null, (str, exc) => {
            reject();
          });
        })
      }
    await impBoard();
    return await impMesh();
}

