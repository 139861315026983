import React, { useEffect } from 'react';
import ProfileFrame from './ProfileFrame'; // Import your existing ProfileFrame component


const ProfileFrameWrapper = ({ mainProfile, secondaryProfile, gameRunning, showSidePanel }) => {
    const style = {
        display: 'flex',
        flexDirection: 'row',
        width: '100px',
        height: '100px',
        border: '2px solid #ddd',
        borderRadius: '8px',
        overflow: 'hidden',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'rgb(213 207 176)',
        position: 'relative',
        bottom: '0px',
        left: '4px',
        padding: '0px'
    }
    const style2 = {
        display: 'flex',
        flexDirection: 'row',
        width: '100px',
        height: '100px',
        border: '2px solid #ddd',
        borderRadius: '8px',
        overflow: 'hidden',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'rgb(213 207 176)',
        position: 'relative',
        top: '0px',
        left: '4px',
        padding: '0px'
    }

    return (
        <div className="profile-frame-wrapper" style={{display: gameRunning ? 'flex':'none',}}>
            {secondaryProfile ?             <div className={ !showSidePanel ? "sliding-profile active":"sliding-profile"} style={{position: 'absolute', top: '0px', left: '0px'}}>
                <ProfileFrame 
                    avatarUrl={secondaryProfile.avatarUrl}
                    name={secondaryProfile.name}
                    eloRating={secondaryProfile.eloRating}
                    country={secondaryProfile.country}
                    style={style2}
                />
            </div>:null}
            <div className={ !showSidePanel ? "sliding-profile active":"sliding-profile"} style={{position: 'absolute', bottom: '0px', left: '0px'}}>
            <ProfileFrame 
                avatarUrl={mainProfile.avatarUrl}
                name={mainProfile.name}
                eloRating={mainProfile.eloRating}
                country={mainProfile.country}
                style={style}
            /></div>
        </div>
    );
};

export default ProfileFrameWrapper;
