import possibleMoves from "../logic/possibleMoves";
import * as BABYLON from '@babylonjs/core';
import io from 'socket.io-client';
import { movePiece, stopMovingPiece } from "../logic/movement";
import socket from "../../socket";
import { moveAnim } from "../3d/animations";
import { dropPiece } from "../logic/game-logic";
import { useGame } from "../../contexts/GameContext";
import { useNavigate } from "react-router-dom";


export const sockets = (client) => {
    const socketio = socket;

    socketio.on('init', function(data) {
        if(client.gameRunning) {
            client.resetBoard(client.scene);
        }
        client.initGame(data);

        console.log(possibleMoves(1, client.board, 1, 1, 6));
      });
      socketio.on('undo', async function() {
        await new Promise((res) => {
            const intervalId = setInterval(() => {
                if (!client.movingPiece) {
                    client.interpolated = false;
                    console.log("undoo");
                    client.lastAnim.stop();
                    client.lastAnimMesh.position = client.lastAnimPosition;
                    client.lastAnimMesh.position.y = 0;
                    
                    if(client.undoCapture) {
                        client.undoCapture.position = client.undoCapturePos;
                        client.undoCapture = false;
                    }
                    clearInterval(intervalId); // Stop the interval
                    res();
                }
            }, 200);
        });
        
      });
      socketio.on('dc', function() {
        let anim = BABYLON.Animation.CreateAndStartAnimation("light", client.light0, "intensity", 10, 1, 1, 0.5);
        anim.loopAnimation = false;
        client.setDcPause(true);
        //dc wait for reconnect
        console.log('player dcd');
      });
      socketio.on('playerReconnected', function() {
        client.setDcPause(false);
        let anim = BABYLON.Animation.CreateAndStartAnimation("light", client.light0, "intensity", 10, 1, 0.5, 1);
        anim.loopAnimation = false;
      });
    
      socketio.on('movePiece', function(data) {
        movePiece(client, data);
        client.setTurn(++client.turn);
        client.interpolated = false;
        client.undoCapture = false;
      });
          
      socketio.on('dropPiece', function(data) {
        dropPiece(client, data);
        client.setTurn(++client.turn);
      });
      socketio.on('updateMmr', function(data) {
        client.setMmrGain(Math.abs(data.mmr - client.mmr));
        client.setMmr(data.mmr);
    
      });
      socketio.on('gameEnd', function(data) {
        client.resetBoard(client.scene);
        if(data.winner == client.username){ client.setGameResult(1);}
        else {client.setGameResult(0);}
        client.setShowGameEnd(true);
        client.setPlayStart(false);
      });
    return socketio;
}

export const reconnect = (client) => {
    const socketio = socket;
    let data = client.rcBoard;
    client.rcBoard = false;
    let newBoard = client.board;

    if(data.user1 == client.username){
        client.userNumber = 1;
        client.setOpp([data.user2, data.mmr2])
    }
    else {
        client.userNumber = 2;

        client.setOpp([data.user1, data.mmr1])
    };
    client.myTurn = client.userNumber == 2 ? data.turn % 2 == 0:data.turn % 2 != 0;
    client.initClient(data);
    client.setTurn(data.turn);

    for(let y = 0; y < 9; y++) {
      for(let x = 0; x < 9; x++) {
        let pceUid = data.board[y][x][2];
        console.log(data.board[y][x][0]);
        if(data.board[y][x][0] == 0) {
          newBoard[y][x] = [0];
          continue;
        }
        console.log(pceUid);
        let dy, dx;
        let mesh;
        movePieceLoop:for(let i = 0; i < 9; i++) {
          for(let v = 0; v < 9; v++) {
            if(client.board[i][v][0] == 0) continue;
            if(client.board[i][v][1].uid == pceUid) {
              

              dx = (x - v) * -0.33;
              dy = (y - i) * 0.365;
              mesh = client.board[i][v][1];
              mesh.position.x += dx;
              mesh.position.z -= dy;
              newBoard[y][x] = client.board[i][v];
              break movePieceLoop;
            }
          }
        }
      }
    }

    for(let i = 0; i < Math.max(client.capturedPieces[0].length, client.capturedPieces[1].length); i++) {

    }

    client.board = newBoard;
    socketio.emit('reconnected');
}

export function waitForOpenSocket(socket) {
    return new Promise((resolve, _reject) => {
      setInterval(() => {
        console.log("test")
        if(socket.connected){ 
          resolve();
          clearInterval(this);
        }
      }, 50)
      // while (socket.readyState !== socket.OPEN) { /* no-op */ }
      // return resolve()
    })
  }

  export async function loadSockets(setAvatar, setCookie, removeCookie, setLoggedIn, setUsername, setMmr, navClick, setSocketid, cookieuuid, client) {


    const socketio = socket;
    if(cookieuuid && cookieuuid !== '') {
        console.log('logging in wit hcookie')
        socketio.emit('login', {uuid: cookieuuid});
    }
    socketio.on('invalidUuid', function() {
        removeCookie('uuid');
      })
      socketio.on('disconnect', function() {

        window.location = "localhost:3000";
        // const rcServer = setInterval(async() => {
        //   socketio = await io.connect('ws://localhost:4000');
        //   if(socketio.connected) {
        //     clearInterval(rcServer);
        //   }
        // }, 14000)
      })
      socketio.on('rcBoard', function(data) {
        console.log("tryyyyying")
        client.rcBoard = data;
      });
      socketio.on('loggedIn', function(data) {
        console.log('loggedin')
        setUsername(data.username.charAt(0).toUpperCase() + data.username.slice(1));
        client.username = data.username;
        console.log(data)
        setAvatar(data.avatar);
        setMmr(data.mmr);
        let expires = new Date();
        expires.setTime(expires.getTime() + 24*60*60*1000);
        setCookie('uuid', data.uuid, {expires: expires});
        
        setLoggedIn(true);

      });
      socketio.on('loginFailed', function() {
      document.getElementById('errorP').innerHTML = '<br>' + "Invalid username or password";
        console.log('Login Faileddddd');
      });
      socketio.on('loginMsg', function(data) {
        console.log(data);
        document.getElementById('errorP').innerHTML = '';
        for(let i = 0; i < data.length; i++) {
          document.getElementById('errorP').innerHTML += '<br>' + data[i];
        }    
      });
        
    return socketio;
  }